import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Customer, CustomerStar } from 'app/shared/models';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, BehaviorSubject, Subject, throwError } from 'rxjs';
import { OdooRpcService, ɵa } from '@cogito/angular-odoo';
import { crmTeamConfig, crmTagConfig } from 'app/configs';
import { CrmStageService } from './crm.stage.service';
import { FuseUtils } from '@fuse/utils';
import { CustomerStarService } from './customer.star.service';
import { AciService } from './aci.service';
import { CrmTagService } from './crm.tag.service';
import { userConfig } from '../../configs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { CustomerLimit } from 'app/shared/utils/customer.limit';
import { AuthService } from 'app/core/auth/auth.service';
import { crmDevStageConfig } from 'app/configs/crm.dev.stage';
import { crmProdStageConfig } from 'app/configs/crm.production.stage';
import { getTax, getAmountWithTax, getTaxRate } from 'app/shared/utils/tax';
import * as _ from 'lodash';
import {  getCurrentUserPermission, isAdminRole, isAnyOutsideSales, isOutsideSalesAus, isOutsideSalesDfw, isOutsideSalesHou, isOutsideSalesSan, isUserRole } from 'app/helpers/user';

@Injectable()
export class CustomerNewService  {
  onCustomerChanged: BehaviorSubject<any>;
  onFilterChanged: Subject<string>;
  onSearchTextChanged: Subject<any>;
  onPaginationCountChanged: BehaviorSubject<any>;
  onFuseProgresStart: Subject<any>;
  onAddressChanged: BehaviorSubject<string>;

  private _customers: any[];
  private _localCustomers: any[];
  private customerStars: CustomerStar[];
  private _filterBy = 'UnClaimedLeads';
  private _searchText = '';
  private _domain: any[];

  constructor(
    private _crmStageService: CrmStageService,
    private _customerStarService: CustomerStarService,
    private _http: HttpClient,
    private _odoo: OdooRpcService,
    private _aciService: AciService,
    private _crmTagService: CrmTagService,
    private _odooCokkies: ɵa,
    private _authService: AuthService,
  ) {
    // Set the defaults
    this._customers = [];

    this._localCustomers = [];
    this.onCustomerChanged = new BehaviorSubject(this._customers);
    this.onFilterChanged = new Subject();
    this.onSearchTextChanged = new Subject();
    this.onPaginationCountChanged = new BehaviorSubject(CustomerLimit.totalItemsCount);

    this.onFuseProgresStart = new Subject();
    this.onAddressChanged = new BehaviorSubject('');

    this.setFilterBy();
  }

  // Resolver
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return new Promise(async (resolve, reject) => {
      /*Promise.all([
        this.getAllCustomers(),
      ]).then((result) => {
        */
      /* this._localCustomers = result[0];*/
      this.onFilterChanged.subscribe((filterBy) => {
        const pageOffset = CustomerLimit.offset * CustomerLimit.limit;
        this._filterBy = this.reteriveStatus(filterBy);

        this.onFuseProgresStart.next();

        if (this._filterBy === 'starred') {
          this.getNewStaredDashBoardData(CustomerLimit.limit, pageOffset);
        } else {
          this.getNewDashBoardData(CustomerLimit.limit, pageOffset);
        }
      });
      this.onSearchTextChanged.subscribe((searchText) => {
        const pageOffset = CustomerLimit.offset * CustomerLimit.limit;
        this._searchText = searchText;
        this.onFuseProgresStart.next();
        this.getNewDashBoardData(CustomerLimit.limit, pageOffset);
      });
      resolve({message:'success'});
      /*}, reject);*/
    });
  }

  /***********************STARRED FILTER START****************************************/
  getFilterBy() {
    return this._filterBy;
  }

  async getMainAddress(siteId, displayName, bFlag = false) {
    return new Promise(async (resolve, reject) => {
      if (siteId > 0) {
        const partnerAddress = await this.getOdooPartnerDetailsById(siteId);
        if (partnerAddress.street) {
          this.onAddressChanged.next(this.formatMainAddress(partnerAddress));
          if (bFlag) {
            resolve(this.formatMainAddress(partnerAddress, bFlag));
          }
        } else {
          this.onAddressChanged.next(displayName);

          resolve(displayName);
        }
      } else {
        this.onAddressChanged.next(displayName);
        resolve(displayName);
      }
    });
  }
  formatMainAddress(partnerAddress, bFlag = false) {
    let customerAddress =
      partnerAddress.street === false ? '' : partnerAddress.street.toString().trim();
    if (bFlag) {
      return customerAddress.trim();
    }

    if (partnerAddress.city !== false) {
      customerAddress += ',';
      customerAddress +=
        partnerAddress.city === false ? '' : ' ' + partnerAddress.city.toString().trim();
    }
    customerAddress +=
      partnerAddress.zip === false ? '' : ', ' + partnerAddress.zip.toString().trim();

    return customerAddress.trim();
  }

  getCustomerCreditScore(data : any,equifaxType,component):Promise<any>{
    let url = '/api/salespro/isoftpull'
    if(environment.CREDIT_SCORE_PROVIDER === 'equifax'){
      data ={...data,equifaxType,component}
      url = '/api/salespro/equifax'
    }else{
      data={...data}
    }
    return new Promise(async (resolve,reject) => {
        this._http.post(url,data).subscribe((response : any)  =>{
          console.log(response)
          resolve(response)
        });
    });
  }

  async getNewStaredDashBoardData(limit, offset): Promise<any> {
    return new Promise(async (resolve, reject) => {
      Promise.all([this._customerStarService.getAllCustomerStarsByLimit(limit, offset)]).then(
        (result) => {
          const customerStars: CustomerStar[] =
            result[0] && result[0].rows.length > 0 ? result[0].rows : [];
          const searchCount: number = result[0] && result[0].count > 0 ? result[0].count : 0;
          this.onPaginationCountChanged.next(searchCount);
          let userOdooIdArray = [];
          if (customerStars && customerStars.length > 0) {
            userOdooIdArray = customerStars.map((item) => {
              return item.odooId;
            });
          }

          Promise.all([
            this.getAllCustomersByLeadIds(userOdooIdArray),
            this.getAllStarredOdooCustomers(userOdooIdArray),
          ]).then((results) => {
            this._localCustomers = results[0];
            this._customers = results[1];

            const localCustomerOdooId = this._localCustomers.map((item) => {
              return item.odooId;
            });
            this._customers = this._customers.map((item) => {
              const isNewTrimLead =
                item.leadType === 'Trim Leads' && localCustomerOdooId.indexOf(item.leadId) === -1;
              return { ...item, isNewTrimLead };
            });
            for (const star of customerStars) {
              const matchedItem = this._customers.find((item) => item.leadId === star.odooId);
              if (matchedItem) {
                matchedItem.isStarred = true;
              }
            }
            this.onCustomerChanged.next(this._customers);
            resolve(this._customers);
          }, reject);
        },
        reject,
      );
    });
  }

  // Get All Odoo Customer Info
  getAllStarredOdooCustomers(userOdooIdArray): Promise<Customer[]> {
    return new Promise(async (resolve, reject) => {
      try {
        this._domain = [];
        let stageConfig = crmDevStageConfig;
        if (environment.stage === 'test') {
          stageConfig = crmProdStageConfig;
        }
        const currentUser = this._authService.currentAuthValue;
        this._domain.push('&');

        this._domain.push(['type', '=', 'opportunity']);

        /*   if (
          currentUser &&
          (currentUser.hasShowroom || currentUser.hasRenewalLeadsDisplay) &&
          (currentUser.isAdmin || currentUser.isManager)
        ) {
          if (currentUser.hasShowroom) {
            this._domain.push('|');
          }
          if (currentUser.hasRenewalLeadsDisplay) {
            this._domain.push('|');
          }
          this._domain.push('&');
          this._domain.push(['team_id', '=', crmTeamConfig.LEAD_GENERATION.id]);
          this._domain.push(['stage_id', '!=', stageConfig.LEAD_GENERATION.FIELD_REJECTED.id]);
          if (currentUser.hasShowroom) {
            this._domain.push('&');
            this.showroomLeadsDomain();
          }

          if (currentUser.hasRenewalLeadsDisplay) {
            this._domain.push('&');
            this.renewalLeadsDomain();
          }
        } else if (
          currentUser &&
          (currentUser.hasShowroom || currentUser.hasRenewalLeadsDisplay) &&
          currentUser.isUser
        ) {
          if (currentUser.hasRenewalLeadsDisplay && currentUser.hasShowroom) {
            this._domain.push('|');
          }
          if (currentUser.hasShowroom) {
            this.showroomLeadsDomain();
          }
          if (currentUser.hasRenewalLeadsDisplay) {
            this.renewalLeadsDomain();
          }
        } else {
          domain.push('&');
          domain.push(['team_id', '=', crmTeamConfig.LEAD_GENERATION.id]);
          domain.push(['stage_id', '!=', stageConfig.LEAD_GENERATION.FIELD_REJECTED.id]);
        }
*/
        for (let i = 1; i < userOdooIdArray.length; i++) {
          this._domain.push('|');
        }
        for (const odooId of userOdooIdArray) {
          this._domain.push(['id', '=', odooId]);
        }

        const tags = await this._prepareTags();

        let customers = await this._odoo
          .call('crm.lead', 'search_read', [this._domain], {
            fields: [
              'display_name',
              'referred',
              'stage_id',
              'tag_ids',
              'team_id',
              'street',
              'street2',
              'city',
              'state_id',
              'zip',
              'country_id',
              'user_id',
              'site_id',
            ],
            order: 'write_date DESC',
          })
          .toPromise<any[]>();
        /* const searchCount = await this._odoo
          .call('crm.lead', 'search_count', [domain], {})
          .toPromise<any[]>();*/
        customers = customers.map((item) => {
          const tagName = this._getTagName(item.tag_ids, tags);
          let isSelfGen = false;
          let isTrimmed = false;

          if (item.tag_ids && item.tag_ids.length > 0) {
            isSelfGen = item.tag_ids.indexOf(environment.SELF_GEN_LEAD_ID) > -1 ? true : false;
            isTrimmed = item.tag_ids.indexOf(environment.TRIMMED_LEAD_ID) > -1 ? true : false;
          }

          return {
            isOdoo: true,
            leadId: item.id,
            displayName: item.display_name,
            leadType: item.referred
              ? 'Referral'
              : item.tag_ids.indexOf(environment.SELF_GEN_LEAD_ID) > -1
              ? 'Self Gen'
              : 'Trimmed',
            status: tagName ? tagName : item.stage_id[1],
            isStarred: false,
            address: this.prepareCustomerAddress(item),
            isSelfGen,
            isTrimmed,
            salesPerson: item.user_id === false ? '' : item.user_id[1].toString().trim(),

            siteId: item.site_id === false ? '' : item.site_id[0],
          };
        });
        resolve(customers);
        /* this.onPaginationCountChanged.next(searchCount);*/
      } catch (error) {
        reject(error);
      }
    });
  }

  /***********************STARRED FILTER END****************************************/

  /*------------------SEARCH BOX MODIFCATIONS START******************************/
  getSearchLeads(searchText, limit, filterBy = '') {
    return new Promise(async (resolve, reject) => {
      Promise.all([this.getAllSearchLeads(searchText, limit, filterBy)]).then((result) => {
        const searchLeads = result[0];
        resolve(searchLeads);
      }, reject);
    });
  }

  getAllSearchLeads(searchText, limit, filterBy = ''): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        this._domain = [];
        let stageConfig = crmDevStageConfig;
        if (environment.stage === 'test') {
          stageConfig = crmProdStageConfig;
        }
        const currentUser = this._authService.currentAuthValue;
        const allRoles = JSON.parse(localStorage.getItem('allRoles'));
        const userRole = localStorage.getItem('userRole');
        const currentUserPermission = getCurrentUserPermission(userRole);
        this._domain.push('&');
        this._domain.push(['type', '=', 'opportunity']);
         if(this._filterBy && this._filterBy === 'shp-loan-app') {
              this._domain.push("&");
              this._domain.push(['shp_application_ids', '!=', false]);  
          }
        if (filterBy === 'UnClaimedLeads') {
          this._domain.push(['user_id', '=', false]);
        }

        this._searchText = searchText;
        if (searchText && !filterBy) {
          this.searchFilterDomain(currentUser);
        } else if (searchText) {
          this.searchQuickAddressDomain(currentUser);
        }

        if (allRoles && 
          (
            currentUserPermission.isShowroom ||
            currentUserPermission.isRenewal ||
            currentUserPermission.isPhoneSales ||
            currentUserPermission.isOnit
          ) && 
            isAdminRole(allRoles)
        ) {
          
          if (currentUserPermission.isShowroom) {
            this._domain.push('|');
          }
          if (currentUserPermission.isRenewal) {
            this._domain.push('|');
          }
          if (currentUserPermission.isOnit) {
            this._domain.push('|');
          }
          if(currentUserPermission.isPhoneSales){
            this._domain.push('|');
          }
          this.leadGenerationDomain(stageConfig);
          if (currentUserPermission.isShowroom) {
            this._domain.push('&');
            this.showroomLeadsDomain();
          }

          if (currentUserPermission.isRenewal) {
            this._domain.push('&');
            this.renewalLeadsDomain(stageConfig);
          }
          if (currentUserPermission.isPhoneSales) {
            this._domain.push('&');
            this.phoneSalesDomain();
          }
          if (currentUserPermission.isOnit) {
            
            this.onitHomeSalesDomain();
          }
        } else if (
          allRoles &&
          (
            currentUserPermission.isShowroom ||
            currentUserPermission.isRenewal ||
            currentUserPermission.isPhoneSales ||
            currentUserPermission.isOnit
          ) &&
          isUserRole(allRoles) &&
          !isAnyOutsideSales(allRoles)
        ) {
          let orCount = 0;
          if (currentUserPermission.isRenewal) {
            orCount += 1;
          }
          if (currentUserPermission.isShowroom) {
            orCount += 1;
          }
          if (currentUserPermission.isPhoneSales) {
            orCount += 1;
          }
          if (currentUserPermission.isOnit) {
            orCount += 1;
          }

          if (orCount > 1) {
            for (let j = 1; j < orCount; j++) {
              this._domain.push('|');
            }
          }

          if (currentUserPermission.isShowroom) {
            this._domain.push('&');
            this.showroomLeadsDomain();
          }
          if (currentUserPermission.isRenewal) {
            this._domain.push('&');
            this.renewalLeadsDomain(stageConfig);
          }
          if (currentUserPermission.isPhoneSales) {
            this._domain.push('&');
            this.phoneSalesDomain();
          }
          if (currentUserPermission.isOnit) {
            this.onitHomeSalesDomain();
          }
        } /* not neccessary*/ else if (
            currentUserPermission.isShowroom ||
            currentUserPermission.isRenewal ||
            currentUserPermission.isPhoneSales ||
            currentUserPermission.isOnit
        ) {
          if (currentUserPermission.isShowroom) {
            this._domain.push('|');
          }
          if (currentUserPermission.isRenewal) {
            this._domain.push('|');
          }
          if (currentUserPermission.isPhoneSales) {
            this._domain.push('|');
          }
          if (currentUserPermission.isOnit) {
            this._domain.push('|');
          }
          this.leadGenerationDomain(stageConfig);
          if (currentUserPermission.isShowroom) {
            this._domain.push('&');
            this.showroomLeadsDomain();
          }

          if (currentUserPermission.isRenewal) {
            this._domain.push('&');
            this.renewalLeadsDomain(stageConfig);
          }
          if (currentUserPermission.isPhoneSales) {
            this._domain.push('&');
            this.phoneSalesDomain();
          }

          if (currentUserPermission.isOnit) {
            
            this.onitHomeSalesDomain();
          }
        } else {
          this.leadGenerationDomain(stageConfig);
        }

        const tags = await this._prepareTags();
        const orderText = 'write_date DESC';
        /*if (filterBy === 'UnClaimedLeads') {
          orderText = 'name ASC';
        }*/
        let customers = await this._odoo
          .call('crm.lead', 'search_read', [this._domain], {
            fields: ['display_name'],
            order: orderText,
            limit,
          })
          .toPromise<any[]>();

        customers = customers.map((item) => {
          return {
            leadId: item.id,
            displayName: item.display_name,
          };
        });
        if (filterBy === 'UnClaimedLeads') {
          customers.push({
            leadId: 0,
            displayName: 'Address Not Available!',
          });
        }

        resolve(customers);
      } catch (error) {
        reject(error);
      }
    });
  }

  /*------------------SEARCH BOX MODIFCATIONS END******************************/

  setFilterBy() {
    const currentUser = this._authService.currentAuthValue;
    const allRoles = JSON.parse(localStorage.getItem('allRoles'));
    const userRole = localStorage.getItem('userRole');
    if(userRole){
      const currentUserPermission = getCurrentUserPermission(userRole);
      if (allRoles && currentUserPermission.isShowroom && isAdminRole(allRoles)) {
        this._filterBy = 'UnClaimedLeads';
      } else if (allRoles && currentUserPermission.isShowroom && allRoles.isManager) {
        this._filterBy = 'UnClaimedLeads';
      } else if (allRoles && currentUserPermission.isShowroom && isUserRole(allRoles) && !isAnyOutsideSales(userRole)) {
        this._filterBy = 'my-leads';
      } else if (allRoles && currentUserPermission.isOnit && isUserRole(allRoles)) {
        this._filterBy = 'my-leads';
      }else {
        this._filterBy = 'UnClaimedLeads';
      }
    }
  }
  async getNewDashBoardData(limit, offest, odooId = '', mode = ''): Promise<any> {
    return new Promise(async (resolve, reject) => {
      
      if (mode === 'initial') {
        this.setFilterBy();
      }
      Promise.all([
        this.getAllOdooCustomers(limit, offest, odooId) /* star logic start end*/,
        /* this._customerStarService.getAllCustomerStars(),*/
        /*this.getAllCustomers(),*/
      ]).then((result) => {
        this._customers = result[0];

        let userOdooIdArray = [];
        if (this._customers && this._customers.length > 0) {
          userOdooIdArray = this._customers.map((item) => {
            return item.leadId;
          });
        }
        if(this._filterBy ==='shp-loan-app'){
          this.getAllCustomersByLeadIds(userOdooIdArray).then((result)=>{
            const localCustomerOdooId = result.map((item) => {
              return item.odooId;
            });
            this._customers = this._customers.filter((customer)=> localCustomerOdooId.includes(customer.leadId));
            this.onCustomerChanged.next(this._customers);
            resolve(this._customers);
          },reject);
        }else{
        Promise.all([
          this.getAllCustomersByLeadIds(userOdooIdArray),
          this._customerStarService.getAllCustomerStarsByLeadIds(userOdooIdArray),
        ]).then((results) => {
          this._localCustomers = results[0];
          const customerStars: CustomerStar[] = results[1];
          const localCustomerOdooId = this._localCustomers.map((item) => {
            return item.odooId;
          });
          this._customers = this._customers.map((item) => {
            const isNewTrimLead =
              item.leadType === 'Trim Leads' && localCustomerOdooId.indexOf(item.leadId) === -1;
            return { ...item, isNewTrimLead };
          });
          for (const star of customerStars) {
            const matchedItem = this._customers.find((item) => item.leadId === star.odooId);
            if (matchedItem) {
              matchedItem.isStarred = true;
            }
          }
          this.onCustomerChanged.next(this._customers);
          resolve(this._customers);
        }, reject);
      }

        /* star logic start*/
        // const customerStars: CustomerStar[] = result[1];
        /* const localCustomerOdooId = this._localCustomers.map((item) => {
          return item.odooId;
        });

        this._customers = this._customers.map((item) => {
          const isNewTrimLead =
            item.leadType === 'Trim Leads' && localCustomerOdooId.indexOf(item.leadId) === -1;
          return { ...item, isNewTrimLead };
        });*/
        /*for (const star of customerStars) {
          const matchedItem = this._customers.find((item) => item.leadId === star.odooId);
          if (matchedItem) {
            matchedItem.isStarred = true;
          }
        }*/
        /* star logic start end*/

        /*if (this._filterBy === '' || this._filterBy === 'all') {*/

        /*} else {
          this.filterByStatus();
        }*/
      }, reject);
    });
  }

  async getFundingUrl(
    _customerGuid,
    _odooCustomerId,
    paymentType,
    paymentStatus,
    _remote = '',
  ): Promise<any> {
    // const billingAccountNumber = new Date().getTime();
    const billingAccountNumber = _odooCustomerId;
    return new Promise(async (resolve, reject) => {
      try {
        const fundingResult = await this._aciService.addFunding(
          paymentType || 'cash',
          paymentStatus || undefined,
          billingAccountNumber,
          _customerGuid,
          _odooCustomerId,
          _remote,
        );
        const fundingResultCode =
          fundingResult['add-funding-response'].message[0]['message-code'][0];
        const fundingUrl = fundingResult['add-funding-response']['add-funding-url'][0];

        return resolve({
          fundingResultCode,
          fundingUrl,
        });
      } catch (error) {
        return reject(error);
      }
    });
  }

  generatePaymentNote(currentCustomer) {
    const {
      docuMonitoring,
      docuMonthlyTax,
      docuTotalRmr,
      docuPaymentViaAci,
      docuPaymentViaLendPro,
      docuAdditionalEquipment,
      docuAddOnTotal,
      docuPlanName,
      integrationFee,
      products,
    } = currentCustomer;
    const tableStart = '<table>';

    let customerProducts = [];
    let productHeading = '';
    let productItem = '';
    let monthlyServiceFee = '';

    let addOnTotal = '';
    let monthlyTax = '';
    let totalMonthlyServiceFee = '';
    let paymentViaAci = '';
    let paymentViaLendPro = '';
    let additionalEquipment = '';

    const noteHeading =
      '<tr><td>Equipment Information</td></tr><tr><td>---------------------</td></tr>';
    const packageHeading =
      '<tr><td>Package Name</td><td>Price</td><td>Total Price (Including Tax)</td></tr>';
    const packageRow =
      '<tr><td>' +
      docuPlanName +
      '</td><td>$' +
      this.addZeroes(integrationFee) +
      '</td><td> $' +
      this.addZeroes(getAmountWithTax(integrationFee)) +
      '</td></tr><tr><td>&nbsp;</td></tr>';

    try {
      customerProducts = JSON.parse(products);
    } catch (error) {
      customerProducts = [];
    }
    if (customerProducts == null) {
      customerProducts = [];
    }
    if (customerProducts.length > 0) {
      const productHeading1 = '<tr><td>Products</td><td>&nbsp;&nbsp;&nbsp;&nbsp;Quantity</td>';
      const productHeading2 =
        '<td>&nbsp;&nbsp;&nbsp;&nbsp;Price</td><td>Total Price (Including Tax)</td></tr>';
      productHeading = productHeading1 + productHeading2;
      for (const product of customerProducts) {
        const { description, prices } = product;
        const quantity = prices.length;
        const price = this.addZeroes(_.sum(prices) / prices.length);
        const priceWithTax = this.addZeroes(getAmountWithTax(price * quantity));

        productItem +=
          '<tr><td>' +
          description +
          '</td><td>&nbsp;&nbsp;&nbsp;&nbsp;' +
          quantity +
          '</td><td>&nbsp;&nbsp;&nbsp;&nbsp;$' +
          price +
          '</td><td>$' +
          priceWithTax +
          '</td></tr>';
      }
      /*for (let i = 0; i < customerProducts.length; i++) {
        const { description, prices } = customerProducts[i];
        const quantity = prices.length;
        const price = this.addZeroes(_.sum(prices) / prices.length);
        const priceWithTax = this.addZeroes(getAmountWithTax(price * quantity));

        productItem +=
          '<tr><td>' +
          description +
          '</td><td>' +
          quantity +
          '</td><td>$' +
          price +
          '</td><td>$' +
          priceWithTax +
          '</td></tr>';
      }*/
      productItem += '<tr><td>&nbsp;</td></tr>';
    }
    // Monthly Service Fee : 32.95

    if (docuMonitoring && Number(docuMonitoring) > 0) {
      monthlyServiceFee =
        '<tr><td> Monthly Service Fee : $' +
        this.addZeroes(docuMonitoring - docuAddOnTotal) +
        '</td></tr>' +
        '<tr><td>&nbsp;</td></tr>';
    }

    // Add-on fee : 3.00

    if (docuAddOnTotal && Number(docuAddOnTotal) > 0) {
      addOnTotal =
        '<tr><td> Add-on Fee : $' +
        this.addZeroes(docuAddOnTotal) +
        '</td></tr>' +
        '<tr><td>&nbsp;</td></tr>';
    }

    // Tax : 2.97
    if (docuMonthlyTax && Number(docuMonthlyTax) > 0) {
      monthlyTax =
        '<tr><td> Tax : $' +
        this.addZeroes(docuMonthlyTax) +
        '</td></tr>' +
        '<tr><td>&nbsp;</td></tr>';
    }

    // Total Monthly Service Fee : 38.92
    if (docuTotalRmr && Number(docuTotalRmr) > 0) {
      totalMonthlyServiceFee =
        '<tr><td> Total Monthly Service Fee : $' +
        this.addZeroes(docuTotalRmr) +
        '</td></tr>' +
        '<tr><td>&nbsp;</td></tr>';
    }

    // Total paid :
    if (docuPaymentViaAci && Number(docuPaymentViaAci) > 0) {
      paymentViaAci =
        '<tr><td> Total Paid : $' +
        this.addZeroes(docuPaymentViaAci) +
        '</td></tr>' +
        '<tr><td>&nbsp;</td></tr>';
    }

    // Amount Financed :

    if (docuPaymentViaLendPro && Number(docuPaymentViaLendPro) > 0) {
      paymentViaLendPro =
        '<tr><td> Amount Financed : $' +
        this.addZeroes(docuPaymentViaLendPro) +
        '</td></tr>' +
        '<tr><td>&nbsp;</td></tr>';
    }
    // Total Amount not financed :

    if (docuAdditionalEquipment && Number(docuAdditionalEquipment) > 0) {
      additionalEquipment =
        '<tr><td> Total Amount not financed : $' +
        this.addZeroes(docuAdditionalEquipment) +
        '</td></tr>' +
        '<tr><td>&nbsp;</td></tr>';
    }

    const tableEnd = '</table>';

    const note =
      tableStart +
      noteHeading +
      packageHeading +
      packageRow +
      productHeading +
      productItem +
      monthlyServiceFee +
      addOnTotal +
      monthlyTax +
      totalMonthlyServiceFee +
      paymentViaAci +
      paymentViaLendPro +
      additionalEquipment +
      tableEnd;

    return note;
  }

  private addZeroes(value) {
    let newValue = value;

    if (value !== '' && value !== null) {
      const num = String(value);
      const dec = num.split('.')[1];

      if (dec) {
        newValue = (Math.round(Number(num) * 100) / 100).toFixed(2);
      } else {
        newValue = Number(num) + '.00';
      }
    } else {
    }

    return newValue;
  }
  addInternalNote(customerId, message: string, attachmentIds: number[] = []): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const newNoteId = await this._odoo
          .call('crm.lead', 'message_post', [customerId], {
            attachment_ids: attachmentIds,
            body: message,
            content_subtype: 'html',
            message_type: 'comment',
            partner_ids: [],
            subtype: 'mail.mt_note',
          })
          .toPromise();
        const newNote = await this._odoo
          .call('mail.message', 'message_format', [newNoteId], {})
          .toPromise<any>();
        return resolve({
          author: newNote[0].author_id[1],
          body: newNote[0].body,
          date: newNote[0].date,
          type: newNote[0].message_type,
          subTypeDescription: newNote[0].subtype_description,
          tracking: newNote[0].tracking_value_ids,
        });
      } catch (error) {
        return reject(error);
      }
    });
  }

  // uploadAttachment(
  //   odooCustomerId,
  //   attachment: any,
  //   fileName: string,
  //   isbtoa = true,
  // ): Promise<number> {
  //   const extention = fileName.split('.');
  //   const modifiedFileName =
  //     extention[0].replace(/[^a-z0-9]/gi, '-').toLowerCase() + '.' + extention[1];
  //   return this._odoo
  //     .call(
  //       'ir.attachment',
  //       'create',
  //       [
  //         {
  //           res_model: 'crm.lead',
  //           res_id: odooCustomerId,
  //           name: modifiedFileName,
  //           type: 'binary',
  //           datas: isbtoa ? btoa(attachment) : attachment,
  //           datas_fname: modifiedFileName,
  //         },
  //       ],
  //       {},
  //     )
  //     .toPromise();
  // }

  // Get Customer Info
  uploadAttachmentByApi(odooCustomerId, attachment: any, fileName: string): Promise<number> {
    const extention = fileName.split('.');
    const modifiedFileName =
      extention[0].replace(/[^a-z0-9]/gi, '_').toLowerCase() + '.' + extention[1];
    const apiUrl = `/api/salespro/customers/remote/uploadAttachmentByApi`;

    return new Promise((resolve, reject) => {
      try{
      this._http
        .post(apiUrl, {
          odooCustomerId,
          attachment,
          modifiedFileName,
        })
        .subscribe((response: any) => {}, reject);
   }catch (error) {
    return reject(error);
  } });
  }

  /* update partner details*/
  // Update the Selected Odoo Customer Info
  updateOdooPartner(id, updateContent: any) {
    console.log('Update Content', updateContent);
    return new Promise((resolve, reject) => {
      try{
      this._odoo
        .call('res.partner', 'write', [[id], updateContent], {})
        .subscribe((updated: any) => {
          console.log('Updated Partner Customer', updated);
          resolve(updated);
        }, reject);
   }catch (error) {
    return reject(error);
  } });
  }
  /* update partner details */
  /*********************GET Partner Details Start**********************************/
  getOdooPartnerDetailsById(id): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let partner = await this._odoo
          .call('res.partner', 'search_read', [[['id', '=', id]]], {
            fields: [
              'title',
              'company_id',
              'city',
              'customer',
              'street',
              'showroom_appt_uid',
              'country_id',
              'email',
              'street2',
              'state_id',
              'active',
              'builder_id',
              'user_id',
              'zip',
              'type',
              'phone',
              'company_name',
              'display_name',
              'is_home_owner',
              'mobile',
              'is_builder'
            ],
          })
          .toPromise<any>();

        partner = partner[0];
        resolve({
          city:
            partner === undefined || partner.city === false ? '' : partner.city.toString().trim(),
          state:
            partner === undefined || partner.state_id === false
              ? ''
              : partner.state_id[1].toString().trim(),
          zip: partner === undefined || partner.zip === false ? '' : partner.zip.toString().trim(),
          displayName:
            partner === undefined || partner.display_name === false ? '' : partner.display_name,
          email: partner === undefined || partner.email === false ? '' : partner.email,
          phone: partner === undefined || partner.phone === false ? '' : partner.phone,
          showroomApptUser:
            partner === undefined || partner.showroom_appt_uid === false
              ? ''
              : partner.showroom_appt_uid[1].toString().trim(),
          street:
            partner === undefined || partner.street === false
              ? ''
              : partner.street.toString().trim(),
          isHomeOwner: partner === undefined || partner.is_home_owner === true ? true : false,
          mobile: partner === undefined || partner.mobile === false ? '' : partner.mobile,
          isBuilder: partner === undefined || partner.is_builder === true ? true : false,
        });
      } catch (error) {
        return reject(error);
      }
    });
  }
  /*********************GET Partner Details END**********************************/

  // Get Odoo Customer Detail Info by Id
  getOdooCustomerDetailsById(id): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let customer = await this._odoo
          .call('crm.lead', 'search_read', [[['id', '=', id]]], {
            fields: [
              'display_name',
              'probability',
              'referred',
              'stage_id',
              'team_id',
              'email_from',
              'user_id',
              'partner_id',
              'phone',
              'tag_ids',
              'street',
              'street2',
              'city',
              'state_id',
              'zip',
              'country_id',
              'name',
              'site_id',
              'message_ids',
              'builder_id',
              'community_id',
              'contact_name',
              'crm_lead_type_id',
              'showroom_appt_uid',
              'branch_id',
            ],
          })
          .toPromise<any>();
        customer = customer[0];
        let customerMessages = [];
        if (customer.message_ids && customer.message_ids.length > 0) {
          const messageDomain = [];
          for (let i = 0; i < customer.message_ids.length - 1; i++) {
            messageDomain.push('|');
          }
          for (const mesId of customer.message_ids) {
            messageDomain.push(['id', '=', mesId]);
          }
          customerMessages = await this._odoo
            .call('mail.message', 'message_format', [customer.message_ids], {})
            .toPromise<any>();
          customerMessages = customerMessages.map((item) => {
            return {
              author: item.author_id[1],
              body: item.body,
              date: item.date,
              type: item.message_type,
              subTypeDescription: item.subtype_description,
              tracking: item.tracking_value_ids,
            };
          });
          console.log(customerMessages);
        }

        const tags = await this._prepareTags();
        const tagName = this._getTagName(customer.tag_ids, tags);
        let isSelfGen = false;
        let isTrimmed = false;
        let isShowroom = false;
        let isRenewal = false;
        let isPhoneSales = false;
        let isOnitHomeLead = false;
        if (customer.tag_ids && customer.tag_ids.length > 0) {
          isSelfGen = customer.tag_ids.indexOf(environment.SELF_GEN_LEAD_ID) > -1 ? true : false;
          isTrimmed = customer.tag_ids.indexOf(environment.TRIMMED_LEAD_ID) > -1 ? true : false;
        }
        isShowroom = customer.team_id[0] === crmTeamConfig.SHOWROOM.id ? true : false;
        isRenewal = customer.team_id[0] === crmTeamConfig.RENEWAL.id ? true : false;
        isPhoneSales = customer.team_id[0] === crmTeamConfig.PHONE_SALES.id ? true : false;
        isOnitHomeLead = customer.team_id[0] === crmTeamConfig.ONIT_HOME_SALES.id ? true : false;

        let street = customer.street === false ? '' : customer.street.toString().trim();
        street += customer.street2 === false ? '' : ' ' + customer.street2.toString().trim();
        if (!isSelfGen && !isTrimmed && !isShowroom && !isRenewal && !isPhoneSales && !isOnitHomeLead) {
          isTrimmed = true;
        }

        resolve({
          isOdoo: true,
          address: this.prepareCustomerAddress(customer),
          street,
          city: customer.city === false ? '' : customer.city.toString().trim(),
          state: customer.state_id === false ? '' : customer.state_id[1].toString().trim(),
          zip: customer.zip === false ? '' : customer.zip.toString().trim(),
          builderId: customer.builder_id === false ? '' : customer.builder_id[0],
          builderName:
            customer.builder_id === false ? '' : customer.builder_id[1].toString().trim(),
          community:
            customer.community_id === false ? '' : customer.community_id[1].toString().trim(),
          displayName: customer.display_name,
          email: customer.email_from,
          phone: customer.phone,
          salesPerson: customer.user_id[1],
          salesType: customer.team_id[1],
          status: tagName ? tagName : customer.stage_id[1],
          messages: customerMessages,
          tag_ids: customer.tag_ids,
          name: customer.name,
          siteName: customer.site_id === false ? '' : customer.site_id[1].toString().trim(),
          country: customer.country_id === false ? '' : customer.country_id[1].toString().trim(),
          isSelfGen,
          isTrimmed,
          isShowroom,
          isRenewal,
          isPhoneSales,
          isOnitHomeLead,
          contact_name: customer.contact_name ? customer.contact_name : '',
          crm_lead_type_id:
            customer.crm_lead_type_id === undefined || customer.crm_lead_type_id === false
              ? 6
              : customer.crm_lead_type_id[1].toString().trim(),
          billingContact:
            customer.partner_id === false ? '' : customer.partner_id[1].toString().trim(),
          billingContactId: customer.partner_id === false ? '' : customer.partner_id[0],
          siteId: customer.site_id === false ? '' : customer.site_id[0],
          showRoomSalesPerson:
            customer.showroom_appt_uid === false || customer.showroom_appt_uid === undefined
              ? ''
              : customer.showroom_appt_uid[1].toString().trim(),
          stageId: customer.stage_id === false ? '' : customer.stage_id[0],
          branchId: customer.branch_id === false ? '' : customer.branch_id[0],
          branchname: customer.branch_id === false ? '' : customer.branch_id[1].toString().trim(),
          street2: customer.street2 ? customer.street2 : '',
        });
      } catch (error) {
       return reject(error)

      }
    });
  }

  sortRolesData(userRoles,currentPermission) {
    let existUserRoles = [];
    userRoles.map( role=>{
      switch(role){
          case 'Outside Sales-DFW' : isOutsideSalesDfw(currentPermission) ? 
                existUserRoles.push({name : role, role : 'role:outside-sales-dfw'}) : null;
            break;
          case 'Outside Sales-HOU' : isOutsideSalesHou(currentPermission) ? 
                existUserRoles.push({name : role, role : 'role:outside-sales-hou'}) : null;
            break;
          case 'Outside Sales-AUS' : isOutsideSalesAus(currentPermission) ? 
                existUserRoles.push({name : role, role : 'role:outside-sales-aus'}) : null;
            break;
          case 'Outside Sales-SAN' : isOutsideSalesSan(currentPermission) ? 
                existUserRoles.push({name : role, role : 'role:outside-sales-san'}) : null;
            break; 
          case 'OnIt Home User' : currentPermission.implicitRolesForUser.includes('role:onit') ? 
                existUserRoles.push({name : role, role : 'role:onit'}) : null;
            break;
          case '3G Renewal User' : currentPermission.implicitRolesForUser.includes('role:3g-upgrade') ? 
                existUserRoles.push({name : role, role : 'role:3g-upgrade'}) : null;
            break;
      }
    });
    return existUserRoles;
  }

  prepareCustomerName(newCustomer) {
    let name = '';
    // if (newCustomer.streetType) {
    //   name = `${newCustomer.streetNumber} ${newCustomer.streetName}
    // ${newCustomer.streetType}, ${newCustomer.city}, ${newCustomer.zipCode}`;
    // } else {
    //   name = `${newCustomer.streetNumber} ${newCustomer.streetName}, ${newCustomer.city}, ${newCustomer.zipCode}`;
    // }
    // if (newCustomer.streetType) {
    //   name = `${newCustomer.streetName} ${newCustomer.streetType}, ${newCustomer.city}, ${newCustomer.zipCode}`;
    // } else {
    //   name = `${newCustomer.streetName}, ${newCustomer.city}, ${newCustomer.zipCode}`;
    // }
    name = `${newCustomer.streetName}, ${newCustomer.city}, ${newCustomer.zipCode}`;
    let nameString = name; // just an example
    nameString = nameString.replace(/[^\w\s]/gi, '');

    if (nameString.trim() === '') {
      return `${newCustomer.firstName} ${newCustomer.lastName}`;
    } else {
      return name;
    }
  }
  // private function prepareCustomerAddress
  prepareCustomerAddress(customer): string {
    let customerAddress = customer.street === false ? '' : customer.street.toString().trim();
    customerAddress += customer.street2 === false ? '' : ' ' + customer.street2.toString().trim();
    if (customer.city !== false || customer.state !== false) {
      customerAddress += ',';
      customerAddress += customer.city === false ? '' : ' ' + customer.city.toString().trim();
      customerAddress +=
        customer.state_id === false ? '' : ' ' + customer.state_id.toString().trim();
    }
    customerAddress += customer.zip === false ? '' : ', ' + customer.zip.toString().trim();
    customerAddress +=
      customer.country_id === false ? '' : ', ' + customer.country_id.toString().trim();
    return customerAddress.trim();
  }

  // Get All Odoo Customer Info
  private _prepareTags(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const allTags = await this._crmTagService.getAllCrmTags();
        const tags = [];
        for (const tagName of Object.values(crmTagConfig.status)) {
          const selectedTag = allTags.find((tag: any) => {
            return tag && tag.name === tagName;
          });
          if (selectedTag && selectedTag.id) {
            tags.push(selectedTag);
          }
        }
        resolve(tags);
      } catch (error) {
       reject(error);
      }
    });
  }

  private _getTagName(tagIds: Array<number>, tags: any): string {
    let tagName = '';
    for (const tagId of tagIds) {
      const selectedTag = tags.find((tag: any) => {
        return tag && tag.id === tagId;
      });
      if (selectedTag && selectedTag.name) {
        tagName = selectedTag.name;
      }
    }
    return tagName;
  }

  getAllGroups(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const usersByGroups = await this._odoo
          .call('res.groups', 'search_read', [], {})
          .toPromise();
        let users = [];
        usersByGroups.map((group) => {
          users = [...users, ...group.users];
        });
        resolve(usersByGroups);
      } catch (error) {
       reject(error);
      }
    });
  }
  reteriveStatus(filterBy) {
    let status = '';
    /*if (filterBy === 'working-opportunity') {
      status = 'Working Opportunity';
    }
    if (filterBy === 'closed-won') {
      status = 'Closed - WON';
    }
    if (filterBy === 'installed') {
      status = 'Install Scheduled';
    }
    if (filterBy === 'referral') {
      status = 'Referral';
    }
    if (filterBy === 'appt-set') {
      status = 'appt-set';
    }*/

    if (filterBy === 'new-trim-leads') {
      status = 'new-trim-leads';
    }
    if (filterBy === 'branding-available') {
      status = 'branding-available';
    }
    if (filterBy === 'branding-sold-sign') {
      status = 'branding-sold-sign';
    }
    if (filterBy === 'prospecting') {
      status = 'prospecting';
    }
    if (filterBy === 'appointment-set') {
      status = 'appointment-set';
    }
    if (filterBy === 'presentation-given') {
      status = 'presentation-given';
    }
    if (filterBy === 'won') {
      status = 'won';
    }
    if (filterBy === 'lost') {
      status = 'lost';
    }

    if (filterBy === 'trimmed') {
      status = 'trimmed';
    }
    if (filterBy === 'self-gen') {
      status = 'self-gen';
    }
    if (filterBy === 'starred') {
      status = 'starred';
    }

    if (filterBy === 'my-leads') {
      status = 'my-leads';
    }

    if (filterBy === 'UnClaimedLeads') {
      status = 'UnClaimedLeads';
    }

    if (filterBy === 'showroom') {
      status = 'showroom';
    }

    if (filterBy === 'renewal') {
      status = 'renewal';
    }
    if (filterBy === 'phone_sales') {
      status = 'phone_sales';
    }

    if (filterBy === 'onit_home_sales') {
      status = 'onit_home_sales';
    }
    if (filterBy === 'shp-loan-app') {
      status = 'shp-loan-app';
    }

    return status;
  }

  // Get All Odoo Customer Info

  filterByDomain(currentUser) {
    let stageConfig = crmDevStageConfig;
    if (environment.stage === 'test') {
      stageConfig = crmProdStageConfig;
    }
    /*if (this._filterBy && this._filterBy === 'appt-set') {
      this._domain.push('|');
      this._domain.push(['stage_id', 'ilike', 'Appt Scheduled']);
      this._domain.push(['stage_id', 'ilike', 'Appointment Scheduled']);
    }*/
    if (this._filterBy && this._filterBy === 'new-trim-leads') {
      this._domain.push(['stage_id', '=', stageConfig.LEAD_GENERATION.NEW_TRIM_LEADS.id]);
    } else if (this._filterBy && this._filterBy === 'branding-available') {
      this._domain.push(['stage_id', '=', stageConfig.LEAD_GENERATION.BRANDING_AVAILABLE.id]);
    } else if (this._filterBy && this._filterBy === 'branding-sold-sign') {
      this._domain.push(['stage_id', '=', stageConfig.LEAD_GENERATION.BRANDING_SOLD_SIGN.id]);
    } else if (this._filterBy && this._filterBy === 'prospecting') {
      this._domain.push(['stage_id', '=', stageConfig.LEAD_GENERATION.PROSPECTING.id]);
    } else if (this._filterBy && this._filterBy === 'appointment-set') {
      this._domain.push(['stage_id', '=', stageConfig.LEAD_GENERATION.APPOINTMENT_SET.id]);
    } else if (this._filterBy && this._filterBy === 'presentation-given') {
      this._domain.push(['stage_id', '=', stageConfig.LEAD_GENERATION.PRESENTATION_GIVEN.id]);
    } else if (this._filterBy && this._filterBy === 'won') {
      this._domain.push(['stage_id', '=', stageConfig.LEAD_GENERATION.WON.id]);
    } else if (this._filterBy && this._filterBy === 'lost') {
      this._domain.push(['stage_id', '=', stageConfig.LEAD_GENERATION.LOST.id]);
    } else if (this._filterBy && this._filterBy === 'trimmed') {
      this._domain.push(['tag_ids', 'ilike', environment.TRIMMED_LEAD_ID]);
    } else if (this._filterBy && this._filterBy === 'self-gen') {
      this._domain.push(['tag_ids', 'ilike', environment.SELF_GEN_LEAD_ID]);

      // referred
    } else if (this._filterBy && this._filterBy === 'Referral') {
      this._domain.push(['referred', '!=', false]);
    } else if (this._filterBy && this._filterBy === 'my-leads') {
      if (currentUser && currentUser.name) {
        this._domain.push(['user_id', 'ilike', currentUser.name]);
      }
    } else if (this._filterBy && this._filterBy === 'UnClaimedLeads') {
      this._domain.push(['user_id', '=', false]);
    } else if (this._filterBy && this._filterBy === 'starred') {
    } else if (this._filterBy && this._filterBy === 'showroom') {
    } else if (this._filterBy && this._filterBy === 'renewal') {
    } else if (this._filterBy && this._filterBy === 'phone_sales') {
    } else if (this._filterBy && this._filterBy === 'onit_home_sales') {
    }else if (this._filterBy && this._filterBy === 'shp-loan-app') {
      if (currentUser && currentUser.name) {
        this._domain.push(['user_id', 'ilike', currentUser.name]);
        this._domain.push("&");
        this._domain.push(['shp_application_ids', '!=', false]);
      }
    }else if (this._filterBy) {
      this._domain.push(['stage_id', 'ilike', this._filterBy]);
    }
  }
  onitHomeSalesDomain() {
     this._domain.push(['team_id', '=', crmTeamConfig.ONIT_HOME_SALES.id]);
  }
  phoneSalesDomain() {
    this._domain.push(['team_id', '=', crmTeamConfig.PHONE_SALES.id]);
  }
  renewalLeadsDomain(stageConfig) {
    // this._domain.push(['team_id', '=', crmTeamConfig.RENEWAL.id]);
    // this._domain.push('|');
    // this._domain.push('|');
    // this._domain.push('|');
    // this._domain.push('|');
    // this._domain.push(['stage_id', '=', crmTeamConfig.RENEWAL_TO_DO.id]);
    // this._domain.push(['stage_id', '=', crmTeamConfig.FIRST_ATTEMPT.id]);
    // this._domain.push(['stage_id', '=', crmTeamConfig.SECOND_ATTEMPT.id]);
    // this._domain.push(['stage_id', '=', crmTeamConfig.THIRD_ATTEMPT.id]);
    // this._domain.push(['stage_id', '=', crmTeamConfig.FOURTH_ATTEMPT.id]);
    this._domain.push(['team_id', '=', crmTeamConfig.LEAD_GENERATION.id]);
    this._domain.push(['stage_id', '!=', stageConfig.LEAD_GENERATION.FIELD_REJECTED.id]);
  }
  leadGenerationDomain(stageConfig) {
    this._domain.push('&');
    this._domain.push(['team_id', '=', crmTeamConfig.LEAD_GENERATION.id]);
    this._domain.push(['stage_id', '!=', stageConfig.LEAD_GENERATION.FIELD_REJECTED.id]);
  }
  showroomLeadsDomain() {
    this._domain.push(['team_id', '=', crmTeamConfig.SHOWROOM.id]);
    this._domain.push('|');
    if (environment.stage === 'test') {
      this._domain.push(['stage_id', '=', crmTeamConfig.AWAITING_HOWNER_VISIT_LIVE.id]);
      this._domain.push(['stage_id', '=', crmTeamConfig.HOWNER_FOLLOW_UP_LIVE.id]);
    } else {
      this._domain.push(['stage_id', '=', crmTeamConfig.AWAITING_HOWNER_VISIT.id]);
      this._domain.push(['stage_id', '=', crmTeamConfig.HOWNER_FOLLOW_UP.id]);
    }
  }

  /* OLD searchFilterDomain */
  searchFilterDomain(currentUser) {
    this._domain.push('|');
    this._domain.push('|');
    this._domain.push('|');
    this._domain.push('|');
    this._domain.push('|');
    if (currentUser.hasShowroom) {
      this._domain.push('|');
    }
    this._domain.push(['street', 'ilike', this._searchText]);
    this._domain.push(['city', 'ilike', this._searchText]);
    this._domain.push(['partner_id', 'ilike', this._searchText]);
    this._domain.push(['partner_name', 'ilike', this._searchText]);
    this._domain.push(['email_from', 'ilike', this._searchText]);
    this._domain.push(['name', 'ilike', this._searchText]);
    if (currentUser.hasShowroom) {
      this._domain.push(['showroom_appt_uid', 'ilike', this._searchText]);
    }
  }
  /* OLD searchFilterDomain */
  /* NEW searchFilterDomain */
  searchQuickAddressDomain(currentUser) {
    this._domain.push('|');
    this._domain.push('|');
    /* this._domain.push('|');
    this._domain.push('|');
    this._domain.push('|');*/
    /*if (currentUser.hasShowroom) {
      this._domain.push('|');
    }*/
    this._domain.push(['street', 'ilike', this._searchText]);
    this._domain.push(['city', 'ilike', this._searchText]);
    /*this._domain.push(['partner_id', 'ilike', this._searchText]);
    this._domain.push(['partner_name', 'ilike', this._searchText]);
    this._domain.push(['email_from', 'ilike', this._searchText]);*/
    this._domain.push(['name', 'ilike', this._searchText]);
    /*if (currentUser.hasShowroom) {
      this._domain.push(['showroom_appt_uid', 'ilike', this._searchText]);
    }*/
  }
  /* NEW searchFilterDomain */
  getAllOdooCustomers(limit, offset, odooId = ''): Promise<Customer[]> {
    return new Promise(async (resolve, reject) => {
      try {
        this._domain = [];
        let stageConfig = crmDevStageConfig;
        if (environment.stage === 'test') {
          stageConfig = crmProdStageConfig;
        }
        const currentUser = this._authService.currentAuthValue;
        const allRoles = JSON.parse(localStorage.getItem('allRoles'));
        const userRole = localStorage.getItem('userRole');
        if(userRole){
          const currentUserPermission = getCurrentUserPermission(userRole);
          if (odooId === '') {
            if (this._filterBy || this._searchText) {
              this._domain.push('&');
            }
            this._domain.push(['type', '=', 'opportunity']);
            this.filterByDomain(currentUser);
            /*************************NOt neccesary*****************/
            this._searchText = '';

            if (this._searchText) {
              this.searchFilterDomain(currentUser);
            }
            /*************************NOt neccesary*****************/

            if (currentUserPermission.isOnit && this._filterBy === 'onit_home_sales') {
              this.onitHomeSalesDomain();
            } else if (currentUserPermission.isRenewal && this._filterBy === 'renewal') {
              this.renewalLeadsDomain(stageConfig);
            } else if (currentUserPermission.isShowroom && this._filterBy === 'showroom') {
              this.showroomLeadsDomain();
            } else if (currentUserPermission.isPhoneSales && this._filterBy === 'phone_sales') {
              this.phoneSalesDomain();
            } else if (
              allRoles &&
              (
                currentUserPermission.isShowroom ||
                currentUserPermission.isRenewal ||
                currentUserPermission.isPhoneSales ||
                currentUserPermission.isOnit
              ) &&
              isAdminRole(allRoles)
            ) {
              if (currentUserPermission.isShowroom) {
                this._domain.push('|');
              }
              if (currentUserPermission.isRenewal) {
                this._domain.push('|');
              }
              if (currentUserPermission.isPhoneSales) {
                this._domain.push('|');
              }
              if (currentUserPermission.isOnit) {
                this._domain.push('|');
              }

              this.leadGenerationDomain(stageConfig);

              if (currentUserPermission.isShowroom) {
                this._domain.push('&');
                this.showroomLeadsDomain();
              }

              if (currentUserPermission.isRenewal) {
                this._domain.push('&');
                this.renewalLeadsDomain(stageConfig);
              }
              if (currentUserPermission.isPhoneSales) {
                this._domain.push('&');
                this.phoneSalesDomain();
              }
              if (currentUserPermission.isOnit) {
                this.onitHomeSalesDomain();
              }
            } else if (
              allRoles &&
              (
                currentUserPermission.isShowroom ||
                currentUserPermission.isRenewal ||
                currentUserPermission.isPhoneSales ||
                currentUserPermission.isOnit
              ) &&
              isUserRole(allRoles) &&
              !isAnyOutsideSales(userRole)
            ) {
              let orCount = 0;
              if (currentUserPermission.isRenewal) {
                orCount += 1;
              }
              if (currentUserPermission.isShowroom) {
                orCount += 1;
              }
              if (currentUserPermission.isPhoneSales) {
                orCount += 1;
              }

              if (currentUserPermission.isOnit) {
                orCount += 1;
              }

              if (orCount > 1) {
                for (let j = 1; j < orCount; j++) {
                  this._domain.push('|');
                }
              }

              if (currentUserPermission.isShowroom) {
                this._domain.push('&');
                this.showroomLeadsDomain();
              }
              if (currentUserPermission.isRenewal) {
                this._domain.push('&');
                this.renewalLeadsDomain(stageConfig);
              }
              if (currentUserPermission.isPhoneSales) {
                this._domain.push('&');
                this.phoneSalesDomain();
              }

              if (currentUserPermission.isOnit) {
                this.onitHomeSalesDomain();
              }

              
            } else {
              if (currentUserPermission.isShowroom) {
                this._domain.push('|');
              }
              this.leadGenerationDomain(stageConfig);
              if (currentUserPermission.isShowroom) {
                this._domain.push('&');
                this.showroomLeadsDomain();
              }
            }
          } else {
            this._domain.push(['id', '=', odooId]);
          }
          const tags = await this._prepareTags();

          let customers = await this._odoo
            .call('crm.lead', 'search_read', [this._domain], {
              fields: [
                'display_name',
                'referred',
                'stage_id',
                'tag_ids',
                'team_id',
                'street',
                'street2',
                'city',
                'state_id',
                'zip',
                'country_id',
                'user_id',
                'showroom_appt_uid',
                'site_id',
              ],
              order: 'write_date DESC',
              limit,
              offset,
            })
            .toPromise<any[]>();

          const searchCount = await this._odoo
            .call('crm.lead', 'search_count', [this._domain], {})
            .toPromise<any[]>();
          customers = customers.map((item) => {
            const tagName = this._getTagName(item.tag_ids, tags);
            let isSelfGen = false;
            let isTrimmed = false;

            if (item.tag_ids && item.tag_ids.length > 0) {
              isSelfGen = item.tag_ids.indexOf(environment.SELF_GEN_LEAD_ID) > -1 ? true : false;
              isTrimmed = item.tag_ids.indexOf(environment.TRIMMED_LEAD_ID) > -1 ? true : false;
            }
            const leadType =
              item.team_id[0] === crmTeamConfig.ONIT_HOME_SALES.id ? "Onit Home" :
              item.team_id[0] === crmTeamConfig.SHOWROOM.id
                ? 'Show Room'
                : item.team_id[0] === crmTeamConfig.RENEWAL.id
                ? '3G Renewal'
                : item.team_id[0] === crmTeamConfig.PHONE_SALES.id
                ? 'Phone Sales'
                : item.referred
                ? 'Referral'
                : item.tag_ids.indexOf(environment.SELF_GEN_LEAD_ID) > -1
                ? 'Self Gen'
                : 'Trimmed';
            return {
              isOdoo: true,
              leadId: item.id,
              displayName: item.display_name,
              leadType,
              status: tagName ? tagName : item.stage_id[1],
              isStarred: false,
              address: this.prepareCustomerAddress(item),
              isSelfGen,
              isTrimmed,
              salesPerson: item.user_id === false ? '' : item.user_id[1].toString().trim(),
              siteId: item.site_id === false ? '' : item.site_id[0],
            };
          });

          resolve(customers);
          if (odooId === '') {
            this.onPaginationCountChanged.next(searchCount);
          }
        }
      } catch (error) {
        return reject(error);
      }
    });
  }

  // Get All Customer Info
  getAllCustomers(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http.get(`/api/salespro/customers`).subscribe((response: any) => {
        let values: any[] = response;
        values = values.map((item) => {
          return new Customer(item);
        });
        resolve(values);
      }, reject);
  });
  }

  // Get all customers by odoo ids
  getAllCustomersByLeadIds(userOdooIdArray): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http
        .post(`/api/salespro/customers/getAllCustomersByLeadIds`, userOdooIdArray)
        .subscribe((response: any) => {
          let values: any[] = response;
          values = values.map((item) => {
            return new Customer(item);
          });
          resolve(values);
        }, reject);
    });
  }

  // Get Customer Info
  getCustomerByGuid(guid: string, remote = ''): Promise<Customer> {
    let apiUrl = `/api/salespro/customers/${guid}`;
    if (remote === 'remote') {
      apiUrl = `/api/salespro/customers/remote/${guid}`;
    }
    return new Promise((resolve, reject) => {
      this._http.get(apiUrl).subscribe((response: any) => {
        const customer: Customer = new Customer(response);
        resolve(customer);
      }, reject);
    });
  }

  // Retry sales API
  retrySalesApi(guid: string,forced = false): Promise<Customer> {
    const apiUrl = `/api/salespro/docusign/retrySalesApi/${guid}/${forced}`;
    return new Promise((resolve, reject) => {
      this._http.get(apiUrl).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }

  // Get Customer Info
  mockDocusignWebHook(guid: string): Promise<any> {
    const apiUrl = `/api/salespro/docusign/CompleteSiging/${guid}`;
    return this._http.post(apiUrl, {}).toPromise();
  }

  // Get Customer by oddo Id
  getCustomerByOdooId(odooId: string): Promise<Customer> {
    return new Promise((resolve, reject) => {
      this._http.get(`/api/salespro/customers/odooId/${odooId}`).subscribe((response: any) => {
        if (response) {
          const customer: Customer = new Customer(response);
          resolve(customer);
        } else {
          return resolve(null);
        }
      }, reject);
    });
  }

  // getTagId
  _getOdooTagId(tagName: string): Promise<number> {
    return new Promise(async (resolve, reject) => {
      try {
        let currentTagId = 0;
        const tags = await this._crmTagService.getAllCrmTags();
        const selectedTag = tags.find((tag: any) => {
          return tag && tag.name === tagName;
        });
        if (selectedTag && selectedTag.id) {
          currentTagId = selectedTag.id;
        } else {
          currentTagId = await this._crmTagService.addNewCrmTag(tagName);
        }
        resolve(currentTagId);
      } catch (error) {
        return reject(error);
      }
    });
  }

  // Add New Odoo Customer
  addNewOdooCustomer(newCustomer: Customer): Promise<number> {
    return new Promise(async (resolve, reject) => {
      try {
        // Get the Current Stage from Odoo
        let currentTeamId = -1;
        switch (newCustomer.leadType) {
          case 'Trimmed':
            currentTeamId = crmTeamConfig.LEAD_GENERATION.id;
            break;
          case 'Self Gen':
            currentTeamId = crmTeamConfig.LEAD_GENERATION.id;
            break;
          default:
            currentTeamId = crmTeamConfig.LEAD_GENERATION.id;
            break;
        }
        if (currentTeamId <= -1) {
          return reject(`Cannot find the matched team.`);
        }
        const currentTagId = await this._getOdooTagId(crmTagConfig.status.CUSTOMER_INFO_ADDED); // can be replace by hardCoding
        let leadTypeTagId = environment.SELF_GEN_LEAD_ID;
        if (newCustomer.leadType === 'Trimmed') {
          leadTypeTagId = environment.TRIMMED_LEAD_ID;
        }
        const data = {
          city: newCustomer.city,
          display_name: `${newCustomer.display_name}`,
          email_from: newCustomer.email,
          name: this.prepareCustomerName(newCustomer),
          phone: newCustomer.formattedPhone,
          tag_ids: [[6, 0, [currentTagId, leadTypeTagId]]],
          // street: `${newCustomer.streetNumber} ${newCustomer.streetName} ${newCustomer.streetType}`,
          street: `${newCustomer.streetName}`,
          team_id: currentTeamId,
          type: 'opportunity',
          // x_contract: 38,
          zip: newCustomer.zipCode,
          referred: newCustomer.referred ? newCustomer.referred : false,
          contact_name: `${newCustomer.firstName} ${newCustomer.lastName}`,
          crm_lead_type_id: 6,
          branch_id: newCustomer.branch,
        };
        if (
          newCustomer.leadType === 'Trimmed' &&
          newCustomer.partner_id !== undefined &&
          newCustomer.partner_id
        ) {
          data['partner_id'] = newCustomer.partner_id;
        }

        const newCustomerId = await this._odoo.call('crm.lead', 'create', [data], {}).toPromise();
        console.log('Created Customer Id', newCustomerId);

        const promises = [];

        promises.push(
          this._odoo
            .call('crm.lead', 'search_read', [[['id', '=', newCustomerId]]], {
              limit: 1,
            })
            .toPromise(),
        );

        promises.push(
          this.addInternalNote(
            newCustomerId,
            'Stage Changed' +
              '<ul>' +
              '<li>' +
              '    Stage:' +
              '        <span> ' +
              crmTagConfig.status.CUSTOMER_INFO_ADDED +
              '</li>' +
              '</ul>',
          ),
        );

        const [newOdooCustomer] = await Promise.all(promises);

        console.log('New Odoo Customer', newOdooCustomer);
        resolve(newCustomerId);
      } catch (error) {
        return reject(error);
      }
    });
  }

  // create partner start

  createPartner(newCustomer: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const newPartnerId = this._odoo
          .call(
            'res.partner',
            'create',
            [
              {
                email: newCustomer.email,
                phone: newCustomer.formattedPhone,
                type: 'contact',
                name: `${newCustomer.firstName} ${newCustomer.lastName}`,
                is_home_owner: newCustomer.isHomeOwner,
              },
            ],
            {},
          )
          .toPromise();

        resolve(newPartnerId);
      } catch (error) {
        return reject(error);
      }
    });
  }

  updateTrimmedOdooCustomerNew(odooCustomerId: number, newCustomer: any, tagIds: Array<number>) {
    const currentTeamId = newCustomer.isOnitHome ? crmTeamConfig.ONIT_HOME_SALES.id : newCustomer.isShowroom
      ? crmTeamConfig.SHOWROOM.id
      : newCustomer.isRenewal
      ? crmTeamConfig.RENEWAL.id
      : newCustomer.isPhoneSales
      ? crmTeamConfig.PHONE_SALES.id
      : crmTeamConfig.LEAD_GENERATION.id;
    return new Promise(async (resolve, reject) => {
      try {
        const { currentTagId, exitingTags, previousTagName } = await this._prepareTagsForUpdate(
          tagIds,
          crmTagConfig.status.CUSTOMER_INFO_ADDED,
        );
        const currentUser = this._authService.currentAuthValue;

        const updateContent = {
          partner_id: newCustomer.partner_id,
          team_id: currentTeamId,
          type: 'opportunity',
          // x_contract: 38,
          tag_ids: [[6, 0, exitingTags]],
          email_from: newCustomer.email,
          phone: newCustomer.formattedPhone,
        };

        console.log('Update Content...haha...', updateContent);
        const promises = [];

        promises.push(
          this._odoo.call('crm.lead', 'write', [[odooCustomerId], updateContent], {}).toPromise(),
        );

        promises.push(
          this.addInternalNote(
            odooCustomerId,
            'Stage Changed' +
              '<ul>' +
              '<li>' +
              '    Stage:' +
              '        <span> ' +
              crmTagConfig.status.CUSTOMER_INFO_ADDED +
              '</li>' +
              '</ul>',
          ),
        );
        await Promise.all(promises);
        return resolve({message:'success'});
      } catch (error) {
        return reject(error);
      }
    });
  }

  // create partner end

  // updateTrimmedOdooCustomer
  updateTrimmedOdooCustomer(
    odooCustomerId: number,
    newCustomer: any,
    tagIds: Array<number>,
    siteName: null,
    address: null,
    leadType = '',
  ) {
    const currentTeamId = newCustomer.isOnitHome ? crmTeamConfig.ONIT_HOME_SALES.id : newCustomer.isShowroom
      ? crmTeamConfig.SHOWROOM.id
      : newCustomer.isRenewal
      ? crmTeamConfig.RENEWAL.id
      : newCustomer.isPhoneSales
      ? crmTeamConfig.PHONE_SALES.id
      : crmTeamConfig.LEAD_GENERATION.id;


    return new Promise(async (resolve, reject) => {
      try {
        const { currentTagId, exitingTags, previousTagName } = await this._prepareTagsForUpdate(
          tagIds,
          crmTagConfig.status.CUSTOMER_INFO_ADDED,
        );
        const currentUser = this._authService.currentAuthValue;

        const updateContent = {
          // display_name: newCustomer.streetName
          //   ? `${address}`
          //   : siteName
          //   ? `${siteName}`
          //   : `${newCustomer.firstName} ${newCustomer.lastName}`,
          // display_name: newCustomer.display_name,
          email_from: newCustomer.email,
          /* name: newCustomer.streetName
            ? `${address}`
            : siteName
            ? `${siteName}`
            : newCustomer.display_name
            ? newCustomer.display_name
            : this.prepareCustomerName(newCustomer),*/
          phone: newCustomer.formattedPhone,
          team_id: currentTeamId,
          type: 'opportunity',
          // x_contract: 38,
          tag_ids: [[6, 0, exitingTags]],
          referred: newCustomer.referred ? newCustomer.referred : false,
          contact_name: `${newCustomer.firstName} ${newCustomer.lastName}`,
          city: newCustomer.city ? newCustomer.city : '',
          zip: newCustomer.zipCode ? newCustomer.zipCode : '',
          user_id: currentUser.uid,
          crm_lead_type_id: newCustomer.crm_lead_type_id,
        };

        if (
          leadType === 'Trimmed' &&
          newCustomer.partner_id !== undefined &&
          newCustomer.partner_id
        ) {
          updateContent['partner_id'] = newCustomer.partner_id;
        }

        let updateShowRoomContent = {};
        if (leadType === 'Show Room' || leadType === 'Renewal' || leadType === 'Phone Sales') {
          updateShowRoomContent = {
            team_id: currentTeamId,
            type: 'opportunity',
            // x_contract: 38,
            tag_ids: [[6, 0, exitingTags]],
            referred: newCustomer.referred ? newCustomer.referred : false,

            user_id: currentUser.uid,
            crm_lead_type_id: newCustomer.crm_lead_type_id,
          };
        }

        console.log('Update Content...haha...', updateContent);
        const promises = [];
        if (leadType !== 'Show Room' && leadType !== 'Renewal' && leadType !== 'Phone Sales') {
          promises.push(
            this._odoo
              .call('crm.lead', 'write', [[odooCustomerId], updateContent], {})
              .toPromise(),
          );
        } else {
          promises.push(
            this._odoo
              .call('crm.lead', 'write', [[odooCustomerId], updateShowRoomContent], {})
              .toPromise(),
          );
        }
        
        if (newCustomer.leadType != 'Self Gen' && 
          ((newCustomer.isCreditFrozen && newCustomer.creditResult?.creditScore) || (!newCustomer.isCreditFrozen && !newCustomer.creditResult?.creditScore))) {
          promises.push(
            this.addInternalNote(
              odooCustomerId,
              'Stage Changed' +
              '<ul>' +
              '<li>' +
              '    Stage:' +
              '        <span> ' +
              crmTagConfig.status.CUSTOMER_INFO_ADDED +
              '</li>' +
              '</ul>',
            ),
          );
        }
        await Promise.all(promises);
        return resolve({message:'success'});;
      } catch (error) {
        return reject(error);
      }
    });
  }

  // Add New Customer Info
  addNewCustomer(newCustomer: Customer) {
    return new Promise((resolve, reject) => {     
      return this._http.post(`/api/salespro/customers`, newCustomer).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }

  // Update the Selected Customer Info
  updateCustomer(customerGuid: string, updatedContent: any, remote = ''): Promise<any> {
    console.log('customer update...........', updatedContent);
    let sUrl = `/api/salespro/customers/${customerGuid}`;
    if (remote === 'remote') {
      sUrl = `/api/salespro/customers/remote/${customerGuid}`;
    }
    return this._http.patch(sUrl, updatedContent).toPromise();
  }
  // Update the Selected Customer Info by odooId
  updateCustomerByOdooId(odooId: number, updatedContent: any): Promise<any> {
    console.log('customer update...........', updatedContent);
    const sUrl = `/api/salespro/customers/odooId/${odooId}`;

    return this._http.patch(sUrl, updatedContent).toPromise();
  }

  // Update the Selected Odoo Customer Info
  updateOdooCustomer(odooCustomerId: number, updateContent: any) {
    console.log('Update Content', updateContent);
    return new Promise((resolve, reject) => {
      this._odoo
        .call('crm.lead', 'write', [[odooCustomerId], updateContent], {})
        .subscribe((updated: any) => {
          console.log('Updated Odoo Customer', updated);
          resolve(updated);
        }, reject);
    });
  }

  // Preparing tags for update
  private _prepareTagsForUpdate(tagIds: Array<number>, tagName: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const tagPromises = [this._prepareTags(), this._getOdooTagId(tagName)];
        const [tags, currentTagId] = await Promise.all(tagPromises);

        const exitingTags = [];
        let previousTagName = '';
        if (typeof tagIds !== 'undefined' && tagIds) {
          for (const tagId of tagIds) {
            const selectedTag = tags.find((tag: any) => {
              return tag && tag.id === tagId;
            });
            if (!selectedTag) {
              exitingTags.push(tagId);
            } else {
              previousTagName = selectedTag.name;
            }
          }
        }
        if (currentTagId) {
          exitingTags.push(currentTagId);
        }
        return resolve({ exitingTags, previousTagName, currentTagId });
      } catch (error) {
        return reject(error);
      }
    });
  }

  // updateOdooCustomerStage(odooCustomerId: number, updatedStageName: string): Promise<any> {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let odooCustomer = await this._odoo
  //         .call('crm.lead', 'search_read', [[['id', '=', odooCustomerId]]], {
  //           fields: ['tag_ids', 'team_id'],
  //           limit: 1,
  //         })
  //         .toPromise<any>();
  //       console.log('Updated Odoo Customer', odooCustomer);
  //       if (!odooCustomer || odooCustomer.length <= 0) {
  //         return reject('Odoo Customer is not existed.');
  //       }
  //       odooCustomer = odooCustomer[0];

  //       const { currentTagId, exitingTags, previousTagName } = await this._prepareTagsForUpdate(
  //         odooCustomer.tag_ids,
  //         updatedStageName,
  //       );

  //       if (currentTagId) {
  //         const updateTagPromises = [
  //           this.updateOdooCustomer(odooCustomerId, {
  //             tag_ids: [[6, 0, exitingTags]],
  //           }),
  //         ];

  //         if (previousTagName) {
  //           updateTagPromises.push(
  //             this.addNewInternalNote(
  //               odooCustomerId,
  //               'Stage Changed' +
  //                 '<ul>' +
  //                 '<li>' +
  //                 '    Stage:' +
  //                 '        <span> ' +
  //                 previousTagName +
  //                 ' </span>' +
  //                 '        <span>-></span>' +
  //                 '    <span>' +
  //                 updatedStageName +
  //                 '    </span>' +
  //                 '</li>' +
  //                 '</ul>',
  //             ),
  //           );
  //         }
  //         await Promise.all(updateTagPromises);
  //       } else {
  //         reject('Failed To update tag');
  //       }
  //       return resolve('sucesss');
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // }

  // Local Changes
  localUpdateCustomerIsStarred(customer: any, isStarredUpdate: boolean) {
    // const customerIndex = this._customers.indexOf(customer);
    this._customers.map((item, index) => {
      if (item.leadId === customer.leadId) {
        this._customers[index].isStarred = isStarredUpdate;
      }
    });

    // this._customers[customerIndex].isStarred = isStarred;
    this.onCustomerChanged.next(this._customers);
    if (isStarredUpdate) {
      return this._customerStarService.addNewCustomerStar(
        new CustomerStar({
          odooId: customer.leadId,
        }),
      );
    } else {
      return this._customerStarService.removeCustomerStar(
        new CustomerStar({
          odooId: customer.leadId,
        }),
      );
    }
  }
  updateOddoTag(customerGuid: string, updatedStageName: string): Promise<any> {
    const sUrl = `/api/salespro/customers/remote/updateOddoTag`;
    return this._http.post(sUrl, { customerGuid, updatedStageName }).toPromise();
  }

  updateRemoteOdooCustomer(customerGuid: string, customerObj: any): Promise<any> {
    const sUrl = `/api/salespro/customers/remote/updateRemoteOdooCustomer`;
    return this._http.post(sUrl, { customerGuid, customerObj }).toPromise();
  }
  uploadAttachmentByAPI(
    odooCustomerId,
    attachment: any,
    fileName: string,
    isbtoa = true,
  ): Promise<any> {
    const extention = fileName.split('.');
    const modifiedFileName =
      extention[0].replace(/[^a-z0-9]/gi, '_').toLowerCase() + '.' + extention[1];
    const sUrl = `/api/salespro/customers/remote/uploadAttachmentByAPI`;
    return this._http
      .post(sUrl, { odooCustomerId, attachment, modifiedFileName, isbtoa })
      .toPromise();
  }
  // Get Customer Info
  addNewInternalNote(
    customerGuid,
    message: string,
    attachmentIds: number[] = [],
  ): Promise<any> {
    const apiUrl = `/api/salespro/customers/remote/addRemoteNewInternalNote`;
    return this._http.post(apiUrl, { customerGuid, message, attachmentIds }).toPromise();
  }
  salesComplete(completeData): Promise<any> {
    const db = environment.odoo.DATABASE;
    const sUrl = `/api/salesproVOne/sale/${db}`;
    const sessionId = this._odooCokkies.getSessionId();
    console.log('===sessionId==', sessionId);
    return this._http
      .post(sUrl, completeData, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Openerp': sessionId,
        }),
      })
      .pipe(
        map(async (item) => {
          return item;
        }),
      )
      .toPromise();
  }

  generateSchedulingUrl(guid: string) {
    // const apiUrl = `/api/salespro/customers/${guid}`;
    const db = environment.odoo.DATABASE;
    const sUrl = `/api/salesproVOne/sale/${db}?${guid}`;
    const sessionId = this._odooCokkies.getSessionId();
    return new Promise((resolve, reject) => {
      this._http.get(sUrl).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }
  updateSchedulingErrorCount(odooCustomerId) {
    const sUrl = `/api/salespro/customers/updateSchedulingErrorCount`;
    return new Promise((resolve, reject) => {
      this._http.post(sUrl, { odooCustomerId }).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }
  getOdooCustomerDiagram(site_id, uid) {
    // site_id = '362140';
    const doc_type = 'diagram_pdf';
    // uid = '884';
    const db = environment.odoo.DATABASE;
    const sUrl = `api/salesproVOne/site/diagram/${db}`;
    const sessionId = this._odooCokkies.getSessionId();

    const formData = new FormData();
    formData.append('site_id', site_id);
    formData.append('doc_type', doc_type);
    formData.append('uid', uid);

    const body = 'site_id=' + site_id + '&doc_type=' + doc_type + '&uid=' + uid;
    const obj = {
      site_id,
      doc_type,
      uid,
    };
    return new Promise((resolve, reject) => {
      try {
        this._http
          .post(sUrl, body, {
            headers: new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded',
              'X-Openerp': sessionId,
            }),
            responseType: 'blob',
          })
          .subscribe((response: any) => {
            resolve(response);
          }, reject);
      } catch (error) {}
    });
  }

  getOdooDiagramId(site_id) {
    const db = environment.odoo.DATABASE;
    const sessionId = this._odooCokkies.getSessionId();
    // const site_id = 366891;
    const sUrl = `/api/salesproVOne/site/diagram/exists/${db}?site_id=${site_id}`;
    return new Promise((resolve, reject) => {
      this._http.get(sUrl).subscribe((response: any) => {
        resolve(response.diagram_id);
      }, reject);
    });
  }
  getOdooDiagramById(diagram_id) {
    const db = environment.odoo.DATABASE;
    const sessionId = this._odooCokkies.getSessionId();
    // const diagram_id = 'd482c980-65fe-4aa5-93ba-0aefd1c4af4c';
    const sUrl = `/api/salesproVOne/site/diagram/download/${db}?diagram_id=${diagram_id}`;
    return this._http.get(sUrl, { responseType: 'blob' }).toPromise();
  }
  async getSaleApiPayloadData(customer) {
    const payloadDomain = [];
    payloadDomain.push(['salespro_req_uuid', '=', customer.guid]);
    payloadDomain.push(['crm_id', '=', parseInt(customer.odooId, 10)]);
    // let payloadData: any = [];
    // if (customer.isRemote) {
      const sUrl = '/api/salespro/customers/remote/getRemotePayloadData';
      const data = {
        guid: customer.guid,
        odooId: customer.odooId,
      };
      return this._http.post(sUrl, data).toPromise();
    // }
    // return new Promise(async (resolve, reject) => {
    //   payloadData = await this._odoo
    //     .call('salespro.sale.payload', 'search_read', [payloadDomain], {
    //       fields: ['sale_payload', 'salespro_sale_contract_id'],
    //     })
    //     .toPromise<any[]>();
    //   resolve(payloadData);
    // });
  }

  // async getSaleApiContractData(customer, id) {
  //   const payloadDomain = [];
  //   payloadDomain.push(['id', '=', id]);
  //   return new Promise(async (resolve, reject) => {
  //     const contractData = await this._odoo
  //       .call('salespro.sale.contract', 'search_read', [payloadDomain], {
  //         fields: ['contract_payment_ids'],
  //       })
  //       .toPromise<any[]>();
  //     resolve(contractData);
  //   });
  // }
  // async getContractPaymentData(customer, id) {
  //   const payloadDomain = [];
  //   payloadDomain.push(['id', '=', id]);
  //   return new Promise(async (resolve, reject) => {
  //     const contractData = await this._odoo
  //       .call('salespro.sale.contract.payment', 'search_read', [payloadDomain], {})
  //       .toPromise<any[]>();
  //     resolve(contractData);
  //   });
  // }
  
  async updateSaleApiPayloadData(id, updateData, customer) {
    // if (customer.isRemote) {
      const sUrl = '/api/salespro/customers/remote/updateRemotePayloadData';
      const data = {
        guid: customer.guid,
        contractId: id,
        updateData,
      };
      return this._http.post(sUrl, data).toPromise();
    // }
    // return new Promise((resolve, reject) => {
    //   this._odoo
    //     .call(
    //       'salespro.sale.contract.payment',
    //       'write',
    //       [
    //         [id],
    //         {
    //           ...updateData,
    //         },
    //       ],
    //       {},
    //     )
    //     .subscribe((updated: any) => {
    //       console.log('Updated sale api payload', updated);
    //       resolve("success");
    //     }, reject);
    // });
  }

  async paymentReceiptGenerate(customer, details): Promise<any> {
    const sUrl = '/api/salespro/customers/receipt/generate';
    return new Promise((resolve, reject) => {
      try {
        this._http.post(sUrl, { customer, details }).subscribe((response) => {
          console.log(response);
          resolve(response);
        }, reject);
      } catch (error) {
        reject(error);
      }
    });
  }
  

}
